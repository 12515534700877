import React, { useEffect } from 'react';
import moment from 'moment';
import { useState } from 'react';
import { DataGrid, GridPagination } from '@mui/x-data-grid';
import {
  selectPrefix,
  selectStationId,
  selectStationName,
  selectUserCode,
  selectUserType,
  selectWidth,
  selectreportFromDate,
  selectreportToDate,
  setreportsFromDate,
  setreportsTodate,
} from '../../Redux/profile';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../../Redux/security';
import {
  selectCollectionAccountDetails,
  setALertStatus,
  setAlertMsg,
  setAlertWithTitle,
  setCollectionAccountDetails,
  setListStationMasterModalStatus,
  setModPartyMasterHelpStatus,
  setSessionExpired,
  setloading,
} from '../../Redux/modal';
import axios from 'axios';
import unique from 'uniqid';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { FormatNumber2 } from '../tools';
import { CollectionSummaryFooter } from './summaryFooter';
import ModListStationMaster from '../modals/modListStationMaster';
import { Button, Skeleton } from '@mui/material';
import { setStationID } from '../../Redux/profile';
import { setStationName } from '../../Redux/profile';
import { FromToDate } from '../../../utility/component/fromToDate';
import GeniusReportsDate from '../reportDate';
import GeniusSelectDate from '../selectDate';
import ModPartyMasterHelp from '../../../master/partyMasterHelp';
import HandleIndexedDB from '../../functions/indexDbDatabase';
import deleteWholeDatabase from '../../functions/deleteWholeDatabase';
import ActionRemarks from '../modals/actionRemarks';

const SummaryDatagrid = () => {
  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);
  const ReduxStation_id = useSelector(selectStationId);
  const dispatch = useDispatch();
  const ReduxStationName = useSelector(selectStationName);
  const accDetails = useSelector(selectCollectionAccountDetails);
  const userType = useSelector(selectUserType);
  const firstDate = useSelector(selectreportFromDate);
  const SecondDate = useSelector(selectreportToDate);
  const [dataLoading, setDataLoading] = useState(false);
  const [voucherSummaryList, setVoucherSummaryList] = useState([]);
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const [remarks, setRemarks] = useState('');
  const { accNumber } = accDetails;
  const [remarksStatus, setRemarksStatus] = useState(false);
  const [currentVoucherNo, setCurrentVoucherNo] = useState('');
  const [firstRowDetails, setFirstRowDetails] = useState('');
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const getCompanyDetails = JSON.parse(localStorage.getItem('companyDetails'));
  const userCode = useSelector(selectUserCode);

  const Approve = (param) => {
    axios
      .post(
        '/api/voucherRoute/approveVoucher',
        {
          table_prefix: prefix,
          VOUCHER_NO: param.row.VOUCHER_NO,
          APPROVED: 'YES',
          REMARKS: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.error) {
          dispatch(setAlertMsg('Successfully Approved'));
          dispatch(setALertStatus(true));
          getAccountData();
        } else {
          dispatch(setAlertMsg(res.data.error));
          dispatch(setALertStatus(true));
        }
      })
      .catch((e) => {
        console.log(e);
        dispatch(setAlertMsg('Something Wrong'));
        dispatch(setALertStatus(true));
      });
  };

  const Reject = (voucher) => {
    axios
      .post(
        '/api/voucherRoute/approveVoucher',
        {
          table_prefix: prefix,
          VOUCHER_NO: voucher,
          APPROVED: 'NO',
          REMARKS: remarks,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.error) {
          dispatch(setAlertMsg('Rejected'));
          dispatch(setALertStatus(true));
          getAccountData();
        } else {
          dispatch(setAlertMsg(res.data.error));
          dispatch(setALertStatus(true));
        }
      })
      .catch((e) => {
        console.log(e);
        dispatch(setAlertMsg('Something Wrong'));
        dispatch(setALertStatus(true));
      });
  };
  console.log(getCompanyDetails);
  const getAccountData = () => {
    setDataLoading(true);
    deleteWholeDatabase('collection_summary');

    axios
      .post(
        `/api/reports/collectionSummary`,
        {
          table_prefix: prefix,
          from_date: firstDate,
          to_date: SecondDate,
          sid: ReduxStation_id,
          ACCOUNT_NO: accDetails.accNumber ? accDetails.accNumber : '',
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (!res.data.error) {
          const datas = res.data.response;
          const data = accDetails.accNumber
            ? [
                {
                  VOUCHER_NO: '',
                  DATE: '',
                  PAY_MODE: '',
                  CARD_NO: '',
                  BANK_NAME: '',
                  EPAYMENT_BANK: '',
                  ACCOUNT_NO: datas[0].ACCOUNT_NO,
                  NAME: '',
                  ADDRESS: '',
                  MOBILE: datas[0].MOBILE,
                  SID: datas[0].SID,
                  SNAME: datas[0].SNAME,
                  EXPENCE_CODE: 0,
                  EXPENCE_NAME: '',
                  AMOUNT_RECEIVED:
                    firstRowDetails?.OPENING_BALANCE_TYPE === 'Cr'
                      ? firstRowDetails?.OPENING_BALANCE
                      : 0,
                  AMOUNT_PAID:
                    firstRowDetails?.OPENING_BALANCE_TYPE === 'Dr'
                      ? firstRowDetails?.OPENING_BALANCE
                      : 0,
                  AMOUNT_DESC: 'Opening Balance',
                  INVNO: 0,
                  LATITUDE: '',
                  LONGITUDE: '',
                  APPROVED: 'YES',
                  REMARKS: '',
                },
                ...datas,
              ]
            : datas;
          if (data[0]) {
            let rowList = [];

            for (let index = 0; index < data.length; index++) {
              if (index === 0) {
                rowList.push({
                  ...data[index],
                  id: index + 1,
                });
              } else {
                rowList.push({
                  ...data[index],
                  id: index + 1,
                });
              }
            }
            setVoucherSummaryList(rowList);
            const databaseName = 'collection_summary';
            const storeName = 'Data';

            const dataToSave = rowList;
            HandleIndexedDB({
              databaseName,
              storeName,
              dataToSave,
              callback: () => {
                const storeName = 'Details';
                const dataToSave = [
                  {
                    SID: ReduxStation_id,
                    SNAME: ReduxStationName,
                    FDATE: moment(firstDate).format('YYYY-MM-DD'),
                    TDATE: moment(SecondDate).format('YYYY-MM-DD'),

                    ICODE: 1,
                    USERCODE: userCode,
                  },
                ];
                HandleIndexedDB({
                  databaseName,
                  storeName,
                  dataToSave,
                });
              },
            });
          } else {
            setVoucherSummaryList([]);
          }
        }
      })
      .catch((e) => {
        console.log(e);

        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setloading(false));
        setDataLoading(false);
      });
  };

  useEffect(() => {
    let db;
    let database = 'collection_summary';
    const request = indexedDB.open(database);
    request.onsuccess = function (event) {
      // Assign the opened database instance to the db variable
      db = event.target.result;
      if (db.objectStoreNames.length > 0) {
        const transaction = db.transaction(db.objectStoreNames, 'readonly');

        const objectStoreNames = transaction.objectStoreNames;
        db.close();
        const databaseName = 'collection_summary';
        const storeNamesArray = Array.from(objectStoreNames);
        const storeName = 'Data';
        if (storeNamesArray[0]) {
          HandleIndexedDB({
            databaseName,
            storeName,
            callback: (data) => {
              const storeName = 'Details';
              HandleIndexedDB({
                databaseName,
                storeName,
                callback: (d) => {
                  setVoucherSummaryList(data);
                  dispatch(setStationID(d[0].SID));
                  dispatch(setStationName(d[0].SNAME));
                  dispatch(
                    setreportsFromDate(moment(d[0].FDATE).format('YYYY-MM-DD'))
                  );
                  dispatch(
                    setreportsTodate(moment(d[0].TDATE).format('YYYY-MM-DD'))
                  );
                  dispatch(
                    setCollectionAccountDetails({
                      accName: d[0].accName,
                      accAddress: d[0].accAddress,
                      accMobile: d[0].accMobile,
                      accNumber: d[0].accNumber,
                      stationId: d[0].stationId,
                    })
                  );
                },
              });
            },
          });
        } else {
          dispatch(
            setCollectionAccountDetails({
              accName: '',
              accAddress: '',
              accMobile: '',
              accNumber: '',
              stationId: '',
            })
          );
          if (userType === 'MANAGER') {
            dispatch(setStationID(''));
            dispatch(setStationName(''));
          }
        }
      } else {
        db.close();
        dispatch(
          setCollectionAccountDetails({
            accName: '',
            accAddress: '',
            accMobile: '',
            accNumber: '',
            stationId: '',
          })
        );
        if (userType === 'MANAGER') {
          dispatch(setStationID(''));
          dispatch(setStationName(''));
        }
        const deleteRequest = indexedDB.deleteDatabase(database);

        deleteRequest.onsuccess = function () {
          console.log('Database deleted successfully');
        };

        deleteRequest.onerror = function (event) {
          console.error('Error deleting database:', event.target.error);
        };
        // Delete the database if no object stores are available
      }
    };
  }, []);

  const okButton = () => {
    if (userType === 'MANAGER') {
      if (ReduxStation_id !== '') {
        getAccountData();
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'Alert',
            msg: 'Please select station first',
          })
        );
      }
    }
    if (userType === 'USER') {
      getAccountData();
    }
  };

  const windowWidth = useSelector(selectWidth);
  console.log(voucherSummaryList);
  const columns = [
    {
      field: 'DATE',
      type: 'string',
      width:
        windowWidth <= 768
          ? 150
          : windowWidth <= 1024
          ? 90
          : windowWidth <= 1280
          ? 90
          : windowWidth <= 1440
          ? 100
          : 100,
      headerAlign: 'center',
      renderCell: (p) => {
        if (p.row.AMOUNT_DESC.includes('Opening Balance')) {
          return <div className="text-red-500">{p.value}</div>;
        }
        return <div>{p.value}</div>;
      },
      valueFormatter: (params) => {
        if (params.value) {
          return moment(params.value).format('YYYY-MM-DD');
        } else {
          return '';
        }
      },

      renderHeader: (params) => <div style={{ fontSize: 12 }}>{'Date'}</div>,
    },

    {
      field: 'VOUCHER_NO',
      type: 'number',
      renderCell: (p) => {
        if (p.row.AMOUNT_DESC.includes('Opening Balance')) {
          return <div className="text-red-500">{p.value}</div>;
        }
        return <div>{p.value}</div>;
      },
      width:
        windowWidth <= 768
          ? 150
          : windowWidth <= 1024
          ? 80
          : windowWidth <= 1280
          ? 70
          : windowWidth <= 1440
          ? 100
          : 100,
      headerAlign: 'center',
      align: 'center',

      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex text-center">
          {' '}
          {'Voucher No.'}
        </div>
      ),
    },

    {
      field: 'ACCOUNT_NO',
      type: 'string',
      renderCell: (p) => {
        if (p.row.AMOUNT_DESC.includes('Opening Balance')) {
          return <div className="text-red-500">{p.value}</div>;
        }
        return <div>{p.value}</div>;
      },
      width:
        windowWidth <= 768
          ? 100
          : windowWidth <= 1024
          ? 80
          : windowWidth <= 1280
          ? 80
          : windowWidth <= 1440
          ? 100
          : 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (p) => {
        if (p.row.AMOUNT_DESC.includes('Opening Balance')) {
          return <div className="text-red-500">{p.value}</div>;
        }
        return <div>{p.value}</div>;
      },
      renderHeader: (params) => (
        <div
          style={{ fontSize: 12 }}
          className="whitespace-normal leading-4 flex text-center"
        >
          {'Account No.'}
        </div>
      ),
    },

    {
      field: 'NAME',
      type: 'string',
      width:
        windowWidth <= 768
          ? 100
          : windowWidth <= 1024
          ? 150
          : windowWidth <= 1280
          ? 180
          : windowWidth <= 1440
          ? 250
          : 300,
      headerAlign: 'center',

      renderHeader: (params) => (
        <div style={{ fontSize: 12 }}>{'Party Name'}</div>
      ),
    },

    {
      field: 'AMOUNT_DESC',
      type: 'number',
      width:
        windowWidth <= 768
          ? 100
          : windowWidth <= 1024
          ? 100
          : windowWidth <= 1280
          ? 170
          : windowWidth <= 1440
          ? 250
          : 150,
      headerAlign: 'center',
      align: 'left',
      renderCell: (p) => {
        if (p.value.includes('Opening Balance')) {
          return <div className="text-red-500">{p.value}</div>;
        }
        return <div>{p.value}</div>;
      },
      renderHeader: (params) => (
        <div
          style={{ fontSize: 12 }}
          className="whitespace-normal leading-4 flex text-center"
        >
          {'Description'}
        </div>
      ),
    },
    {
      field: 'REMARKS',
      type: 'text',
      width:
        windowWidth <= 768
          ? 100
          : windowWidth <= 1024
          ? 100
          : windowWidth <= 1280
          ? 170
          : windowWidth <= 1440
          ? 250
          : 150,
      headerAlign: 'center',
      align: 'left',

      renderHeader: (params) => (
        <div
          style={{ fontSize: 12 }}
          className="whitespace-normal leading-4 flex text-center"
        >
          {'Remarks'}
        </div>
      ),
    },
    {
      field: 'AMOUNT_RECEIVED',
      type: 'string',
      renderCell: (p) => {
        if (p.row.AMOUNT_DESC.includes('Opening Balance')) {
          return <div className="text-red-500">{p.value}</div>;
        }
        return <div>{p.value}</div>;
      },
      width:
        windowWidth <= 768
          ? 130
          : windowWidth <= 1024
          ? 130
          : windowWidth <= 1280
          ? 130
          : windowWidth <= 1440
          ? 150
          : 150,
      headerAlign: 'center',
      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber2(params.value);
        } else {
          return '';
        }
      },

      renderHeader: (params) => (
        <div
          style={{ fontSize: 12 }}
          className="whitespace-normal leading-4  text-center"
        >
          {'Amount Recived '}
          <div>[Collection]</div>
        </div>
      ),
    },

    {
      field: 'AMOUNT_PAID',
      type: 'string',
      renderCell: (p) => {
        if (p.row.AMOUNT_DESC.includes('Opening Balance')) {
          return <div className="text-red-500">{p.value}</div>;
        }
        return <div>{p.value}</div>;
      },
      width:
        windowWidth <= 768
          ? 100
          : windowWidth <= 1024
          ? 80
          : windowWidth <= 1280
          ? 100
          : windowWidth <= 1440
          ? 100
          : 150,
      headerAlign: 'center',
      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber2(params.value);
        } else {
          return '';
        }
      },

      renderHeader: (params) => (
        <div
          style={{ fontSize: 12 }}
          className="whitespace-normal leading-4  text-center"
        >
          {'Amount Paid '}
          <div>[Debit]</div>
        </div>
      ),
    },

    {
      field: 'INVNO',
      type: 'string',
      width:
        windowWidth <= 768
          ? 100
          : windowWidth <= 1024
          ? 80
          : windowWidth <= 1280
          ? 100
          : windowWidth <= 1440
          ? 100
          : 100,
      valueFormatter: (params) => {
        if (params.value) {
          return params.value;
        } else {
          return '';
        }
      },
      headerAlign: 'center',

      renderHeader: (params) => (
        <div style={{ fontSize: 12 }}>{'Bill No.'}</div>
      ),
    },
    {
      field: 'APPROVED',
      type: 'string',
      width:
        windowWidth <= 768
          ? 100
          : windowWidth <= 1024
          ? 150
          : windowWidth <= 1280
          ? 100
          : windowWidth <= 1440
          ? 100
          : 200,

      renderCell: (params) => {
        return (
          <div className="flex w-full justify-center  pr-4">
            <div className="w-full  flex justify-center items-center  ">
              {params.value === 'YES' ? (
                <CheckBoxIcon
                  onClick={() => {
                    if (userType === 'MANAGER') {
                      setRemarksStatus(true);
                      setCurrentVoucherNo(params.row.VOUCHER_NO);
                    } else {
                      dispatch(
                        setAlertWithTitle({
                          title: 'Alert',
                          msg: 'Only manager can change collection status',
                        })
                      );
                    }
                  }}
                  sx={{ color: '#a8cf45' }}
                />
              ) : (
                <CheckBoxOutlineBlankIcon
                  onClick={() => {
                    if (userType === 'MANAGER') {
                      Approve(params);
                    } else {
                      dispatch(
                        setAlertWithTitle({
                          title: 'Alert',
                          msg: 'Only manager can change collection status',
                        })
                      );
                    }
                  }}
                />
              )}

              {/* <Button onClick={() => {
               Reject(params)
             }
          } size={'small'} variant={'outlined'} color={'error'} sx={{ textTransform: "none", marginLeft: 1 }}  > No </Button> */}
            </div>
          </div>
        );
      },
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ fontSize: 12 }}>{'Approved'}</div>
      ),
    },
  ];
  const c = columns;

  return (
    <div className=" w-full h-full flex flex-col  p-2">
      {window.innerWidth <= 500 ? (
        <>
          <div
            style={{
              border: '1px solid #b5b3b3',
            }}
            className="p-1 py-3 rounded-t-sm  flex items-center "
          >
            <div className="px-1 ">From</div>

            <GeniusReportsDate
              date={firstDate}
              onChange={(e) => {
                dispatch(setreportsFromDate(moment(e).format('YYYY-MM-DD')));
                setFromDateFocus(false);
              }}
              dateFocus={fromDateFocus}
              SetDateFocus={setFromDateFocus}
            />
            <div className="px-1 pl-2 ">To</div>
            <GeniusSelectDate
              date={SecondDate}
              onChange={(e) => {
                dispatch(setreportsTodate(moment(e).format('YYYY-MM-DD')));
                setToDateFocus(false);
              }}
              dateFocus={toDateFocus}
              SetDateFocus={setToDateFocus}
            />
          </div>
          <div
            style={{ border: '1px solid #b5b3b3', borderTop: '0px' }}
            className=" w-full"
          >
            {userType === 'USER' ? null : (
              <div className="flex  text-[#4B4545] p-2  ">
                <div
                  onClick={() => {
                    if (userType === 'MANAGER') {
                      dispatch(setListStationMasterModalStatus(true));
                    }
                  }}
                  style={{ fontSize: 13 }}
                  className=" flex whitespace-normal  justify-center items-center hover:cursor-pointer "
                >
                  <div className="flex items-center ">
                    <div>
                      <i
                        className="bi bi-search"
                        style={{ color: '#f44336', fontSize: 15 }}
                      ></i>
                    </div>

                    <span
                      style={{ fontSize: 12, fontWeight: 600 }}
                      className=" text-[#5c5b5b] pl-2 whitespace-nowrap  items-center"
                    >
                      Station ID
                    </span>
                  </div>

                  <div
                    style={{
                      border: '1px dotted #a6a2a2 ',
                      borderRadius: '3px',
                    }}
                    className="flex text-[#a6a2a2]  pl-2 ml-2  "
                  >
                    <input
                      size={'small'}
                      placeholder="All"
                      value={ReduxStation_id ? ReduxStation_id : 'All'}
                      style={{
                        // width: '20%',
                        fontSize: 12,
                        paddingTop: '2px',
                        paddingBottom: '2px',
                        backgroundColor: 'transparent',
                      }}
                      readOnly
                      className=" outline-0 w-6"
                    />

                    <div
                      style={{
                        borderLeft: '1px dotted #a6a2a2 ',
                      }}
                      className="px-2 p-1 w-52 whitespace-nowrap print:hidden"
                    >
                      {ReduxStationName ? `${ReduxStationName}` : null}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {userType === 'USER' ? (
              <div
                style={{
                  borderTop: '1px solid #a6a2a2 ',
                }}
                className="py-2"
              >
                <button
                  onClick={okButton}
                  className="flex items-center rounded-sm ml-2"
                  style={{
                    backgroundColor: '#c1c1c1',
                    paddingLeft: 30,
                    paddingRight: 30,
                    paddingTop: 4,
                    paddingBottom: 4,
                    color: 'black',
                  }}
                >
                  <div className="flex items-center text-sm">Ok</div>
                </button>
              </div>
            ) : (
              <div
                style={
                  userType === 'MANAGER'
                    ? {
                        fontSize: 12,
                        width: '100%',
                        color: '#a6a2a2',
                        borderTop: '1px solid #a6a2a2 ',
                      }
                    : {
                        fontSize: 12,
                        width: '100%',
                        color: '#a6a2a2',
                      }
                }
                className=" flex whitespace-normal  p-2  justify-start items-center  "
              >
                {/* party name 1st part */}
                <div className="flex  h-full items-center ">
                  <div
                    className="flex hover:cursor-pointer h-full items-center"
                    onClick={() => dispatch(setModPartyMasterHelpStatus(true))}
                  >
                    <div>
                      <i
                        className="bi bi-search"
                        style={{ color: '#f44336', fontSize: 15 }}
                      ></i>
                    </div>

                    <span
                      style={{ fontSize: 12 }}
                      className=" text-[#5c5b5b]  pl-2 flex font-semibold items-center"
                    >
                      {' '}
                      Party{' '}
                      <span className="pl-1 text-[#5c5b5b] print:hidden font-semibold ">
                        {' '}
                        Name{' '}
                      </span>
                    </span>
                  </div>

                  <div
                    style={{
                      border: '1px dotted #a6a2a2 ',
                      borderRadius: '3px',
                    }}
                    className="flex  w-20  pl-2 ml-2 "
                  >
                    <input
                      size={'small'}
                      placeholder="All"
                      value={accNumber ? accNumber : 'All'}
                      style={{
                        fontSize: 12,
                        paddingTop: '2px',
                        paddingBottom: '2px',
                        backgroundColor: 'transparent',
                      }}
                      readOnly
                      className=" outline-0 w-30 "
                    />
                  </div>
                </div>
                <div>
                  <button
                    onClick={okButton}
                    className="flex items-center rounded-sm ml-2"
                    style={{
                      backgroundColor: '#c1c1c1',
                      paddingLeft: 30,
                      paddingRight: 30,
                      paddingTop: 4,
                      paddingBottom: 4,
                      color: 'black',
                    }}
                  >
                    <div className="flex items-center text-sm">Ok</div>
                  </button>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <FromToDate
          reset={() => {
            dispatch(
              setCollectionAccountDetails({
                accName: '',
                accAddress: '',
                accMobile: '',
                accNumber: '',
                stationId: '',
              })
            );
            if (userType === 'MANAGER') {
              dispatch(setStationID(''));
              dispatch(setStationName(''));
            }
          }}
          submit={okButton}
        />
      )}

      <div className=" flex py-2 flex-col grow">
        {dataLoading ? (
          <div className="w-full ">
            <div className="flex w-full ">
              {c.map((e, k) => {
                return (
                  <Skeleton
                    animation={'wave'}
                    key={k}
                    width={e.width ? e.width : 100}
                    height={60}
                    style={{
                      marginLeft: 3,
                    }}
                  />
                );
              })}
            </div>
            <div>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e, f) => {
                return (
                  <div key={f} className="flex ">
                    {c.map((e, kf) => {
                      return (
                        <Skeleton
                          key={kf}
                          width={e.width ? e.width : 100}
                          height={50}
                          style={{
                            marginLeft: 3,
                          }}
                        >
                          {' '}
                        </Skeleton>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <DataGrid
            // hideFooterPagination
            // headerHeight={36}
            scrollbarSize={0}
            rowHeight={33}
            columns={columns}
            headerHeight={30}
            rows={voucherSummaryList}
            sx={
              window.innerWidth <= 768
                ? {
                    backgroundColor: 'white',

                    fontSize: 10,
                    '& .super-app-theme--header': {
                      backgroundColor: '#E6E6E3',
                    },
                  }
                : {
                    backgroundColor: 'white',

                    fontSize: 13,
                    '& .super-app-theme--header': {
                      backgroundColor: '#E6E6E3',
                    },
                  }
            }
            components={{
              NoRowsOverlay: () => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    No Records Found
                  </div>
                );
              },
              Pagination: GridPagination,
              // Footer: () => (
              //   // <CollectionSummaryFooter
              //   //   rows={voucherSummaryList}
              //   //   columns={columns}
              //   // />
              // ),
            }}
            pageSize={100}
            rowsPerPageOptions={[100]}
            experimentalFeatures={{ newEditingApi: true }}
            showCellRightBorder={true}
            pagination={true}
            showColumnRightBorder={true}
            disableColumnSelector
            disableColumnReorder
            disableColumnMenu={true}
          />
        )}
        <div className="overflow-x-auto border-b  ">
          <CollectionSummaryFooter
            rows={voucherSummaryList}
            columns={columns}
          />
        </div>
      </div>

      <ModListStationMaster
        onRowClick={(p) => {
          dispatch(setStationID(p.row.CODE));
          dispatch(setStationName(p.row.STATION_NAME));
          dispatch(setListStationMasterModalStatus(false));
        }}
      />

      <ModPartyMasterHelp
        SID={ReduxStation_id}
        onRowClick={(params) => {
          const name = params.row.NAME;
          const mobile = params.row.MOBILE;
          const accNumber = params.row.ACCOUNT_NO;
          const address = params.row.ADDRESS;
          const stationId = params.row.SID;
          setFirstRowDetails(params.row);
          const stationName = params.row.SNAME;
          dispatch(
            setCollectionAccountDetails({
              accName: name,
              accAddress: address,
              accMobile: mobile,
              accNumber: accNumber,
              stationId: stationId,
              sName: stationName,
            })
          );
          if (userType !== 'USER') {
            dispatch(setStationID(stationId));
            dispatch(setStationName(stationName));
          }
          dispatch(setModPartyMasterHelpStatus(false));
        }}
        onClose={() => {
          dispatch(setModPartyMasterHelpStatus(false));
        }}
      />
      <ActionRemarks
        remarksStatus={remarksStatus}
        remarks={remarks}
        setRemarks={setRemarks}
        setRemarksStatus={setRemarksStatus}
        question={'Why are you want to reject this collection?'}
        SubmitButton={
          <Button
            size={'small'}
            onClick={() => {
              Reject(currentVoucherNo);
              setRemarksStatus(false);
              setCurrentVoucherNo('');
              setRemarks('');
            }}
            style={{
              backgroundColor: '#e97a7a',
              color: 'white',

              textTransform: 'none',
              width: 'auto',
              justifyContent: 'start',
              marginRight: '4px',
            }}
            variant="contained"
          >
            <div className="px-6">{'Submit'}</div>{' '}
          </Button>
        }
      />
    </div>
  );
};

export default SummaryDatagrid;
