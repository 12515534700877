import React, { useEffect, useRef } from 'react';

import moment from 'moment';
import { useState } from 'react';
import {
  selectPrefix,
  selectStationId,
  selectStationName,
  selectUserType,
  selectWidth,
  selectreportFromDate,
  selectreportToDate,
  setreportsFromDate,
  setreportsTodate,
} from '../../utility/Redux/profile';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../../utility/Redux/security';

import {
  selectCollectionAccountDetails,
  setCollectionAccountDetails,
  setItemMasterHelpStatus,
  setListStationMasterModalStatus,
  setSessionExpired,
  setloading,
} from '../../utility/Redux/modal';

import axios from 'axios';
import { FormatNumber2, FormatNumber3 } from '../../utility/component/tools';

import { setStationID } from '../../utility/Redux/profile';
import { setStationName } from '../../utility/Redux/profile';
import ModListStationMaster from '../../utility/component/modals/modListStationMaster';
import { ItemWiseFooter } from '../../utility/component/datagrid/footrItemReport';
import { FromToDate } from '../../utility/component/fromToDate';
import ItemMasterHelp from '../../master/iteemMasterHelp';
import GeniusDatagrid from '../../utility/component/datagrid/geniusDatagrid';
import GeniusReportsDate from '../../utility/component/reportDate';
import { Skeleton } from '@mui/material';
import ReactToPrint from 'react-to-print';
import GeniusTableForReports from '../../utility/component/geniusComponents/geniusTableforReports';
import dataToLocal from '../../utility/functions/summaryToLocal';
import IsLocalData from '../../utility/functions/ceckLocalData';
import deleteWholeDatabase from '../../utility/functions/deleteWholeDatabase';
import { ItemWiseIssueFooter } from '../../utility/component/datagrid/footerIssueItemReport';

const ItemWiseIssueReport = () => {
  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);
  const ReduxStation_id = useSelector(selectStationId);
  const dispatch = useDispatch();
  const ReduxStationName = useSelector(selectStationName);
  const printRef = useRef();

  const userType = useSelector(selectUserType);
  const firstDate = useSelector(selectreportFromDate);
  const SecondDate = useSelector(selectreportToDate);
  const [dataLoading, setDataLoading] = useState(false);
  const [list, setList] = useState([]);
  const [itemCode, setItemCode] = useState('');
  const accDetails = useSelector(selectCollectionAccountDetails);
  const width = useSelector(selectWidth);
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const getItemWiseReport = () => {
    setDataLoading(true);
    deleteWholeDatabase('item_Wise_issue_report');
    axios
      .post(
        `/api/issueStockRoute/itemWiseIssueReport`,
        {
          table_prefix: prefix,
          FDATE: firstDate,
          TDATE: SecondDate,
          SID: ReduxStation_id,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (!res.data.error) {
          const data = res.data.response;
          let rowList = [];

          for (let index = 0; index < data.length; index++) {
            rowList.push({
              ...data[index],
              id: index + 1,
            });
          }
          if (rowList[0]) {
            dataToLocal('item_Wise_issue_report', rowList, [
              {
                table_prefix: prefix,
                from_date: firstDate,
                to_date: SecondDate,
                SID: ReduxStation_id,
                ICODE: itemCode ? itemCode : '',
                ACCOUNT_NO: accDetails.accNumber ? accDetails.accNumber : '',
              },
            ]);
          }
          setList(rowList);
        }
      })
      .catch((e) => {
        console.log(e);

        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setloading(false));
        setDataLoading(false);
      });
  };

  const okButton = () => {
    getItemWiseReport();
  };

  const columns = [
    {
      field: 'ICODE',
      type: 'number',
      accessor: 'ICODE',
      width: width < 1280 ? 100 : 100,
      headerAlign: 'center',
      align: 'left',
      valueFormatter: (param) => {
        return param.value;
      },
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div className="whitespace-normal leading-4 flex text-center">
          {' '}
          {'Code'}
        </div>
      ),
    },

    {
      field: 'INAME',
      accessor: 'INAME',
      type: 'string',
      width: width < 1280 ? 150 : 200,
      headerAlign: 'center',
      align: 'left',

      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div
          style={{ fontSize: 12 }}
          className="whitespace-normal leading-4 flex text-center"
        >
          {'Item Name'}
        </div>
      ),
    },

    {
      field: 'IQNTY',
      accessor: 'IQNTY',
      type: 'string',
      width: width < 1280 ? 100 : 100,
      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber3(params.value);
        } else {
          return '';
        }
      },
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div style={{ fontSize: 12 }}>{'Issue Qnty'}</div>
      ),
    },
    {
      field: 'IAMOUNT',
      accessor: 'IAMOUNT',
      type: 'string',
      width: width < 1280 ? 100 : 100,
      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber2(params.value);
        } else {
          return '';
        }
      },
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div style={{ fontSize: 12 }}>{'Issue Amount'}</div>
      ),
    },
    {
      field: 'RQNTY',
      accessor: 'RQNTY',
      type: 'string',
      width: width < 1280 ? 100 : 100,
      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber3(params.value);
        } else {
          return '';
        }
      },
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div style={{ fontSize: 12 }}>{'Return Qnty'}</div>
      ),
    },
    {
      field: 'RAMOUNT',
      accessor: 'RAMOUNT',
      type: 'string',
      width: width < 1280 ? 100 : 100,
      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber2(params.value);
        } else {
          return '';
        }
      },
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div style={{ fontSize: 12 }}>{'Return Amt.'}</div>
      ),
    },
    {
      field: 'SQNTY',
      accessor: 'SQNTY',
      type: 'string',
      width: width < 1280 ? 100 : 100,
      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber3(params.value);
        } else {
          return '';
        }
      },
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div style={{ fontSize: 12 }}>{'Sale Qnty'}</div>
      ),
    },
    {
      field: 'SAMOUNT',
      accessor: 'SAMOUNT',
      type: 'string',
      width: width < 1280 ? 100 : 100,
      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber2(params.value);
        } else {
          return '';
        }
      },
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div style={{ fontSize: 12 }}>{'Sale Amount'}</div>
      ),
    },
  ];

  useEffect(() => {
    const callback = (details, data) => {
      setList(data);
      dispatch(setStationID(details[0].SID));
      dispatch(setItemCode(details[0].ICODE));
      dispatch(
        setreportsTodate(moment(details[0].to_date).format('YYYY-MM-DD'))
      );

      dispatch(
        setreportsFromDate(moment(details[0].from_date).format('YYYY-MM-DD'))
      );
    };
    const elseFunc = () => {
      if (userType !== 'USER') {
        dispatch(setStationID(''));
        dispatch(setStationName(''));
      }
    };
    IsLocalData('item_Wise_issue_report', callback, elseFunc);
  }, []);
  const c = columns;

  return (
    <div className="p-1 w-full h-full items-center ">
      {window.innerWidth <= 500 ? (
        <>
          <div
            style={{
              border: '1px solid #b5b3b3',
            }}
            className="p-1 py-3 rounded-t-sm  flex items-center "
          >
            <div className="px-1 ">From</div>

            <GeniusReportsDate
              date={firstDate}
              onChange={(e) => {
                dispatch(setreportsFromDate(moment(e).format('YYYY-MM-DD')));
                setFromDateFocus(false);
              }}
              dateFocus={fromDateFocus}
              SetDateFocus={setFromDateFocus}
            />
            <div className="px-1 pl-2 ">To</div>
            <GeniusReportsDate
              date={SecondDate}
              onChange={(e) => {
                dispatch(setreportsTodate(moment(e).format('YYYY-MM-DD')));
                setToDateFocus(false);
              }}
              dateFocus={toDateFocus}
              SetDateFocus={setToDateFocus}
            />
          </div>

          <div style={{ border: '1px solid #b5b3b3', borderTop: '0px' }}>
            {userType === 'USER' ? null : (
              <div className="flex  text-[#4B4545] p-2  ">
                <div
                  onClick={() => {
                    if (userType === 'MANAGER') {
                      dispatch(setListStationMasterModalStatus(true));
                    }
                  }}
                  style={{ fontSize: 13 }}
                  className=" flex whitespace-normal  justify-center items-center hover:cursor-pointer "
                >
                  <div className="flex items-center ">
                    <div>
                      <i
                        className="bi bi-search"
                        style={{ color: '#f44336', fontSize: 15 }}
                      ></i>
                    </div>

                    <span
                      style={{ fontSize: 12, fontWeight: 600 }}
                      className=" text-[#5c5b5b] pl-2 whitespace-nowrap  items-center"
                    >
                      Station ID
                    </span>
                  </div>

                  <div
                    style={{
                      border: '1px dotted #a6a2a2 ',
                      borderRadius: '3px',
                    }}
                    className="flex text-[#a6a2a2]  pl-2 ml-3  "
                  >
                    <input
                      size={'small'}
                      placeholder="All"
                      value={ReduxStation_id ? ReduxStation_id : 'All'}
                      style={{
                        // width: '20%',
                        fontSize: 13,
                        paddingTop: '2px',
                        paddingBottom: '2px',
                        backgroundColor: 'transparent',
                      }}
                      readOnly
                      className=" outline-0 w-6"
                    />

                    <div
                      style={{
                        borderLeft: '1px dotted #a6a2a2 ',
                      }}
                      className="px-2 p-1 w-52 whitespace-nowrap print:hidden"
                    >
                      {ReduxStationName ? `${ReduxStationName}` : null}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="pb-2">
              <button
                onClick={okButton}
                className="flex items-center rounded-sm ml-2"
                style={{
                  backgroundColor: '#c1c1c1',
                  paddingLeft: 30,
                  paddingRight: 30,
                  paddingTop: 4,
                  paddingBottom: 4,
                  color: 'black',
                }}
              >
                <div className="flex items-center text-sm">Ok</div>
              </button>
            </div>
          </div>
        </>
      ) : (
        <FromToDate
          submit={okButton}
          otherButton={
            list[0] && width > 1024 ? (
              <ReactToPrint
                trigger={() => {
                  return (
                    <button
                      style={{
                        width: 'auto',
                        borderRadius: '7px',
                        height: 'auto',
                        paddingLeft: 30,
                        paddingRight: 30,
                        paddingTop: 4,
                        paddingBottom: 4,
                        backgroundColor: '#bad4f9',
                        color: 'black',
                        marginLeft: 7,
                      }}
                    >
                      <div className="text-sm">Print</div>
                    </button>
                  );
                }}
                content={() => printRef.current}
              />
            ) : (
              false
            )
          }
          reset={() => {
            if (userType === 'USER') {
              dispatch(
                setCollectionAccountDetails({
                  accName: '',
                  accAddress: '',
                  accMobile: '',
                  accNumber: '',
                  stationId: '',
                })
              );
              setItemCode('');
            } else {
              setItemCode('');
              dispatch(setStationID(''));
              dispatch(setStationName(''));
              dispatch(
                setCollectionAccountDetails({
                  accName: '',
                  accAddress: '',
                  accMobile: '',
                  accNumber: '',
                  stationId: '',
                })
              );
            }
          }}
        />
      )}

      <div
        style={{
          marginTop: 10,
          height: `calc(100% - ${window.innerWidth < 500 ? 250 : 200}px)`,
        }}
      >
        {dataLoading ? (
          <div className="w-full ">
            <div className="flex w-full ">
              {c.map((e, k) => {
                return (
                  <Skeleton
                    animation={'wave'}
                    key={k}
                    width={e.width ? e.width : 100}
                    height={60}
                    style={{
                      marginLeft: 3,
                    }}
                  />
                );
              })}
            </div>
            <div>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e, f) => {
                return (
                  <div key={f} className="flex ">
                    {c.map((e, kf) => {
                      return (
                        <Skeleton
                          key={kf}
                          width={e.width ? e.width : 100}
                          height={50}
                          style={{
                            marginLeft: 3,
                          }}
                        >
                          {' '}
                        </Skeleton>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <GeniusDatagrid
            list={list}
            dataGridHeader={true}
            columns={columns}
            rowID={(r) => r.id}
            showPagination={true}
          />
        )}
      </div>
      {!dataLoading && (
        <div className="w-full flex overflow-x-scroll">
          <ItemWiseIssueFooter rows={list} columns={columns} />
        </div>
      )}

      {width <= 1024 && (
        <div className="w-full flex justify-end mt-2">
          <ReactToPrint
            trigger={() => {
              return (
                <button
                  style={{
                    width: 'auto',
                    borderRadius: '7px',
                    height: 'auto',
                    padding: 10,
                    backgroundColor: '#bad4f9',
                    color: 'black',
                  }}
                >
                  Print Report
                </button>
              );
            }}
            content={() => printRef.current}
          />
        </div>
      )}
      <div
        ref={printRef}
        style={{ width: '100%', display: 'block', visibility: 'hidden' }}
      >
        <GeniusTableForReports
          stationId={ReduxStation_id}
          date={firstDate}
          date1={SecondDate}
          columns={columns}
          data={list}
          Fotter={<ItemWiseIssueFooter rows={list} columns={columns} />}
          title={'Item Wise Issue Report'}
        />
      </div>

      <ModListStationMaster
        onRowClick={(p) => {
          dispatch(setStationID(p.row.CODE));
          dispatch(setStationName(p.row.STATION_NAME));
          dispatch(setListStationMasterModalStatus(false));
        }}
      />
      <ItemMasterHelp
        onRowClick={(p) => {
          setItemCode(p.row.ICODE);
          dispatch(setItemMasterHelpStatus(false));
        }}
        setItemCode={setItemCode}
      />
    </div>
  );
};

export default ItemWiseIssueReport;
