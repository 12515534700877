import { Button } from '@mui/material';
import axios from 'axios';
import { toJpeg, toPng } from 'html-to-image';
import React, { useEffect } from 'react';
import Lottie from 'lottie-react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaWhatsappSquare, FaSms, FaWhatsapp } from 'react-icons/fa';
import { TiMessage } from 'react-icons/ti';
import whitSpinner from '../json/spinerWhite.json';

import {
  setAlertMsg,
  setALertStatus,
  setAlertWithTitle,
  setloading,
  setSessionExpired,
  setSpinnerLoading,
  setviewBillModalStatus,
} from '../Redux/modal';
import jsPDF from 'jspdf';
import spinner from '../json/Spinner.json';
import { selectToken } from '../Redux/security';
import { BiCloudDownload } from 'react-icons/bi';
import { TiArrowBackOutline } from 'react-icons/ti';
import { selectViewBillInvoiceId } from '../Redux/tableOrder';
import ViewBillImage from './viewBillImage';
import { useRef } from 'react';
import {
  selectCompanyAddress,
  selectDefultProfileSetting,
  selectPrefix,
  selectUserType,
  selectWidth,
  setUserType,
} from '../Redux/profile';
import MapWithMarker from './mapmarker';
import { useLocation } from 'react-router-dom';
import { FormatNumber2 } from './tools';
import ModSendMsgStatus from './modals/modSendMsg';
import { DownloadFile, UploadFile } from '../functions/firebase';
import CallAPI from '../functions/getData';
import ActionRemarks from './modals/actionRemarks';
import AlertModalTwoButton from './modals/alertWithTwoButton';
import ReactToPrint from 'react-to-print';

const ViewBill = () => {
  const dispatch = useDispatch();
  const [whatsappStatus, setWhatsappstatus] = useState(false);
  const location = useLocation();
  const [remarksStatus, setRemarksStatus] = useState(false);
  const currentInvoiceNumber = useSelector(selectViewBillInvoiceId);
  const [waDisable, setWadisable] = useState(false);
  const [smsDisable, setsmsdisable] = useState(false);
  const [total, setTotal] = useState();
  const [latitude, setLatitude] = useState('');
  const [longitude, setlongitude] = useState('');
  const prefix = useSelector(selectPrefix);
  const [billLoading, setBillLoading] = useState(false);
  const componentRef = useRef();
  const [billStatus, setBillStatus] = useState(false);
  const token = useSelector(selectToken);
  const [sentdo, seSendTo] = useState('');
  const [remarks, setRemarks] = useState('');

  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
  const companyDetails = useSelector(selectCompanyAddress);
  const defaultProfile = useSelector(selectDefultProfileSetting);
  const userType = useSelector(selectUserType);
  const getViewBillDetatils = () => {
    dispatch(setSpinnerLoading('Loading bill'));
    axios
      .post(
        '/api/reports/viewBill',

        {
          table_prefix: prefix,
          invno: currentInvoiceNumber,
        },

        {
          headers: headers,
        }
      )

      .then((response) => {
        const dataList = response.data.response;
        seSendTo(dataList[0]?.MOBILE);

        setTotal(dataList[0]?.NET_AMOUNT);
        setLatitude(dataList[0]?.LATITUDE);
        setlongitude(dataList[0]?.LONGITUDE);

        if (
          defaultProfile.auto_send_bill === 'YES' &&
          location.pathname === '/' &&
          dataList[0]?.MOBILE
        ) {
          handleSendSms(dataList[0]?.MOBILE, dataList[0]?.NET_AMOUNT);
        }

        if (
          defaultProfile.auto_send_bill === 'YES' &&
          location.pathname === '/'
        ) {
          setWadisable(true);
          const timeoutId = setTimeout(() => {
            handleSendImage(dataList[0]?.MOBILE, dataList[0]?.NET_AMOUNT);
          }, 8000);
          return () => clearTimeout(timeoutId);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  function dataURLToBlob(dataUrl) {
    const arr = dataUrl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }

  const checkWhastappMsgCredits = (number, total) => {
    CallAPI(
      '/api/userSettings/loadMessageSetup',
      {
        company_email: companyDetails.email,
      },
      token,
      (e) => {
        if (!e.data.error) {
          if (
            e.data.response[0]?.whatsapp_balance &&
            e.data.response[0]?.whatsapp_balance > 0
          ) {
            if (number?.length === 10) {
              dispatch(setloading(true));
              setWadisable(true);
              const amount = FormatNumber2(total);
              toPng(
                componentRef.current,
                window.innerWidth < 768 ? { quality: 0.25 } : { quality: 0.95 }
              )
                .then((dataUrl) => {
                  const blob = dataURLToBlob(dataUrl);
                  dispatch(setloading(true));
                  const path = 'rootbilling/bill/';
                  const name = `${prefix + currentInvoiceNumber}`;
                  UploadFile(blob, path, name)
                    .then(() => {
                      const b = `rootbilling/bill/${
                        prefix + currentInvoiceNumber
                      }`;
                      DownloadFile(b).then((c) => {
                        const s = c.split(
                          'https://firebasestorage.googleapis.com/v0/b/genius-office.appspot.com/'
                        );
                        const data = {
                          dataUrl: s[1],
                          invno: currentInvoiceNumber.toString(),
                          mobile: number,
                          amount: amount,
                          company_email: companyDetails.email,
                        };
                        dispatch(setSpinnerLoading('sending image'));
                        axios
                          .post('/api/sendDigitalBill/saveBillImage', data, {
                            headers: headers,
                          })
                          .then((response) => {
                            setWadisable(true);
                            dispatch(
                              setAlertWithTitle({
                                title: 'Success',
                                msg: 'Whatsapp is sent succesfully',
                                lottie: 'success',
                              })
                            );
                            // dispatch(setAlertMsg('Message sent successfully'));
                            // dispatch(setALertStatus(true));
                          })
                          .catch((err) => {
                            console.log(err);
                            dispatch(
                              setAlertWithTitle({
                                title: 'Error',
                                msg: 'Faild to send message',
                                lottie: 'reject',
                              })
                            );
                            if (
                              err?.response?.data?.name === 'TokenExpiredError'
                            ) {
                              dispatch(setSessionExpired(true));
                              dispatch(
                                setAlertMsg('Whatsapp message not sent')
                              );
                              dispatch(setALertStatus(true));
                            }
                          })
                          .finally(() => {
                            dispatch(setloading(false));
                            setWadisable(false);
                            dispatch(setSpinnerLoading(false));
                          });
                      });
                    })
                    .catch((e) => {
                      console.log(e);
                    });
                })
                .catch((err) => {
                  console.log(err);
                })
                .finally(() => {
                  setWadisable(false);
                });
            }
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Alert',
                msg: 'Low Whastapp Credits',
              })
            );
          }
        }
      },
      dispatch
    );
  };

  const handleSendImage = (number, total) => {
    checkWhastappMsgCredits(number, total);
  };

  const handleSendSms = (number, amount) => {
    CallAPI(
      '/api/userSettings/loadMessageSetup',
      {
        company_email: companyDetails.email,
      },
      token,
      (e) => {
        if (!e.data.error) {
          if (
            e.data.response[0]?.sms_balance &&
            e.data.response[0]?.sms_balance > 0
          ) {
            if (number?.length === 10 && amount) {
              dispatch(setloading(true));
              setsmsdisable(true);
              axios
                .post(
                  '/api/sendDigitalBill/sendSms',
                  {
                    INVNO: currentInvoiceNumber,
                    MOBILE: number,
                    AMOUNT: amount,
                    company_email: companyDetails.email,
                  },

                  {
                    headers: headers,
                  }
                )
                .then((response) => {
                  dispatch(setAlertMsg('Text sms sent successfully'));
                  dispatch(setALertStatus(true));
                })
                .catch((err) => {
                  console.log(err);
                  dispatch(setAlertMsg('Text sms not sent'));
                  dispatch(setALertStatus(true));
                })
                .finally(() => {
                  dispatch(setloading(false));
                  setsmsdisable(false);
                });
            }
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Alert',
                msg: 'Low Sms Credits',
              })
            );
          }
        }
      },
      dispatch
    );
  };

  const downloadPdf = () => {
    toJpeg(
      componentRef.current,
      window.innerWidth < 768 ? { quality: 0.25 } : { quality: 0.95 }
    )
      .then((dataUrl) => {
        const pdf = new jsPDF();
        pdf.addImage(dataUrl, 'PNG', 0, 0);
        pdf.save(`Bill_${currentInvoiceNumber}`);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getViewBillDetatils();
  }, []);
  const width = useSelector(selectWidth);
  return (
    <div className="w-full h-fit  flex flex-col md:flex md:flex-row ">
      <div
        style={window.innerWidth < 770 ? { width: '100%' } : { width: '300px' }}
        className="  flex-col flex grow justify-center items-center"
      >
        <div className=" w-full flex flex-col grow justify-center items-center ">
          {billLoading ? (
            <div>
              <div className="w-6 h-6">
                <Lottie
                  animationData={spinner}
                  loop={true}
                  style={{ fontSize: 15 }}
                  color="white"
                />
              </div>
            </div>
          ) : (
            <ViewBillImage setBillStatus={setBillStatus} ref={componentRef} />
          )}

          <ModSendMsgStatus
            status={whatsappStatus}
            setStatus={setWhatsappstatus}
          />
        </div>
      </div>

      <div
        style={{
          display: width <= 768 ? 'none' : 'flex',
        }}
        className="p-5   flex-col   "
      >
        <div className="w-full">
          <div className="flex justify-between w-full  "></div>

          <div className="flex justify-start items-center w-full  ">
            <div style={{ fontSize: 13 }}>Mobile</div>
            <div
              style={{
                height: 27,
                backgroundColor: '#EDEDF2',
                overflow: 'hidden',
                marginLeft: 10,
              }}
              className=" rounded-sm  w-16 md:w-40  items-center "
            >
              <input
                type={'Number'}
                autoFocus={true}
                style={{
                  fontSize: 13,
                  backgroundColor: '#EDEDF2',
                  paddingLeft: 10,

                  fontWeight: 400,
                  outline: 0,
                }}
                placeholder={'123'}
                value={sentdo}
                onChange={(t) => seSendTo(t.target.value)}
                readOnly={
                  setUserType === 'USER'
                    ? defaultProfile?.enable_customer_mobile === 'YES'
                      ? false
                      : true
                    : false
                }
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-5 justify-center">
          <Button
            size={'small'}
            onClick={() => {
              if (!waDisable && sentdo) {
                handleSendImage(sentdo, total);
              } else {
                if (!sentdo) {
                  dispatch(
                    setAlertMsg('Please update your mobile number from admin')
                  );
                  dispatch(setALertStatus(true));
                }
              }
            }}
            style={{
              backgroundColor: '#A8CF45',
              color: 'white',
              textTransform: 'none',
              width: '100%',
              justifyContent: 'start',
              paddingLeft: 20,
            }}
            variant="contained"
          >
            <FaWhatsapp size={20} className="bg-green text-white" />
            <div className="px-2">
              {' '}
              {defaultProfile.auto_send_bill === 'YES' &&
              location.pathname === '/'
                ? 'Auto'
                : 'Whatsapp'}
            </div>{' '}
            {waDisable ? (
              <div className="w-full flex justify-center items-center">
                <div className="w-6 h-6">
                  <Lottie
                    animationData={spinner}
                    loop={true}
                    style={{ fontSize: 15 }}
                    color="white"
                  />
                </div>
              </div>
            ) : null}
          </Button>

          <Button
            size={'small'}
            onClick={() => {
              if (!smsDisable && sentdo) {
                handleSendSms(sentdo, total);
              } else {
                if (!sentdo) {
                  dispatch(
                    setAlertMsg('Please update your mobile number from admin')
                  );
                  dispatch(setALertStatus(true));
                }
              }
            }}
            style={{
              backgroundColor: '#F5874F',
              color: 'white',
              marginTop: 10,
              width: '100%',
              justifyContent: 'start',
              paddingLeft: 20,

              textTransform: 'none',
            }}
            variant="contained"
          >
            <TiMessage size={20} className="bg-orange text-white" />
            <div className="px-2">SMS</div>{' '}
            {smsDisable ? (
              <div className="w-full flex justify-center items-center">
                <div className="w-6 h-6">
                  <Lottie
                    animationData={whitSpinner}
                    loop={true}
                    style={{ fontSize: 15 }}
                    color="white"
                  />
                </div>
              </div>
            ) : null}
          </Button>

          <Button
            size={'small'}
            onClick={() => {
              downloadPdf();
            }}
            style={{
              backgroundColor: '#F5874F5',
              color: 'white',
              marginTop: 10,
              textTransform: 'none',
              width: '100%',
              justifyContent: 'start',
              paddingLeft: 20,
            }}
            variant="contained"
          >
            <BiCloudDownload size={20} className="bg-brown text-white" />
            <div className="px-2">Download</div>{' '}
          </Button>
          {billStatus && userType === 'MANAGER' ? (
            <Button
              size={'small'}
              onClick={() => {
                if (billStatus === 'Settled') {
                  setRemarksStatus(true);
                } else {
                  setAlertTwoButtonModal({
                    ...alertTwoButtonModal,
                    status: true,
                    title: 'Alert',
                    msg: 'Are you sure you want to Settled this bill?',
                    button1: 'Yes',
                    button2: 'Cancel',
                    button2Click: () => {
                      setAlertTwoButtonModal({
                        ...alertTwoButtonModal,
                        status: false,
                      });
                    },
                    button1Click: () => {
                      setAlertTwoButtonModal({
                        ...alertTwoButtonModal,
                        status: false,
                      });
                      dispatch(setSpinnerLoading('Settled'));
                      CallAPI(
                        '/api/billing/voidBill',
                        {
                          table_prefix: prefix,
                          invno: currentInvoiceNumber,
                          bill_status:
                            billStatus === 'Void' ? 'Settled' : 'Void',
                        },
                        token,
                        (e) => {
                          if (!e.data.error) {
                            dispatch(
                              setAlertWithTitle({
                                title: 'Success',
                                msg: `Bill successfully  ${
                                  billStatus === 'Void' ? 'Settled' : 'Deleted'
                                }`,
                              })
                            );
                            dispatch(setviewBillModalStatus(false));
                          } else {
                            dispatch(
                              setAlertWithTitle({
                                title: 'Alert',
                                msg: 'Something went wrong',
                              })
                            );
                          }
                        },
                        dispatch,
                        () => {
                          dispatch(setSpinnerLoading(false));
                        }
                      );
                    },
                  });
                }
              }}
              style={{
                backgroundColor: billStatus === 'Void' ? '#5491f5' : '#e97a7a',
                color: 'white',
                marginTop: 10,

                textTransform: 'none',
                width: '100%',
                justifyContent: 'start',
                paddingLeft: 20,
              }}
              variant="contained"
            >
              <i
                className={`bi ${
                  billStatus === 'Void' ? ' bi-patch-check' : 'bi-trash3'
                }`}
              ></i>
              <div className="px-2">
                {billStatus === 'Void' ? 'Settled' : 'Void'}
              </div>{' '}
              {waDisable ? (
                <div className="w-full flex justify-center items-center">
                  <div className="w-6 h-6">
                    <Lottie
                      animationData={spinner}
                      loop={true}
                      style={{ fontSize: 15 }}
                      color="white"
                    />
                  </div>
                </div>
              ) : null}
            </Button>
          ) : null}
          <Button
            size={'small'}
            onClick={() => dispatch(setviewBillModalStatus(false))}
            style={{
              color: '#383535',
              marginTop: 10,
              borderColor: '#383535',
              textTransform: 'none',
              width: '100%',
              justifyContent: 'start',
              paddingLeft: 20,
            }}
            variant="outlined"
          >
            <TiArrowBackOutline
              //   onClick={() => dispatch(setShareImageModalStatus(false))}
              size={20}
              className=" text-brown"
            />
            <div
              //   onClick={() => dispatch(setShareImageModalStatus(false))}
              className="px-2"
            >
              Back{' '}
            </div>{' '}
          </Button>
        </div>

        <ReactToPrint
          trigger={() => {
            return (
              <Button
                size={'small'}
                onClick={() => dispatch(setviewBillModalStatus(false))}
                style={{
                  backgroundColor: '#BAD4F9',
                  color: 'black',
                  textTransform: 'none',
                  marginTop: 10,
                }}
                variant="contained"
              >
                <div className="px-2">Print</div>{' '}
              </Button>
            );
          }}
          content={() => componentRef.current}
        />

        <div className="w-full flex justify-center mt-3 "></div>
        <MapWithMarker lat={latitude} lng={longitude} />
      </div>

      <div className="fixed bottom-0 w-full flex md:hidden p-3 bg-[#EDEDF2] items-center border-t-2 border-t-slate-200 ">
        <div
          style={{
            height: 35,
            backgroundColor: '#c9c5c5',
            overflow: 'hidden',
            width: '45%',
          }}
          className=" rounded-md mx-2 border-2  items-center "
        >
          <input
            type={'Number'}
            autoFocus={true}
            style={{
              fontSize: 14,
              backgroundColor: '#c9c5c5',
              paddingLeft: 10,
              height: '100%',
              fontWeight: 400,
              outline: 0,
            }}
            placeholder={'Mobile Number'}
            value={sentdo}
            onChange={(t) => seSendTo(t.target.value)}
            readOnly={
              defaultProfile?.enable_customer_mobile === 'YES' ? false : true
            }
          />
        </div>
        <div
          style={{ width: '55%' }}
          className=" rounded-sm flex justify-evenly "
        >
          <FaWhatsappSquare
            size={30}
            className="text-green"
            onClick={() => {
              if (!waDisable && sentdo) {
                handleSendImage(sentdo, total);
                // handleSendSms(sentdo);
              } else {
                if (!sentdo) {
                  dispatch(
                    setAlertMsg('Please update your mobile number from admin')
                  );
                  dispatch(setALertStatus(true));
                }
              }
            }}
          />

          <FaSms
            size={30}
            className="text-orange"
            onClick={() => {
              if (!smsDisable && sentdo) {
                handleSendSms(sentdo, total);
              } else {
                if (!sentdo) {
                  dispatch(
                    setAlertMsg('Please update your mobile number from admin')
                  );
                  dispatch(setALertStatus(true));
                }
              }
            }}
          />

          <Button
            size={'small'}
            onClick={() => dispatch(setviewBillModalStatus(false))}
            style={{
              backgroundColor: '#383535',
              color: 'white',
              textTransform: 'none',
            }}
            variant="contained"
          >
            <div className="px-2">Back</div>{' '}
          </Button>
        </div>
      </div>
      <ActionRemarks
        remarksStatus={remarksStatus}
        remarks={remarks}
        setRemarks={setRemarks}
        question={'Do you really want to Delete this Bill?'}
        setRemarksStatus={setRemarksStatus}
        SubmitButton={
          <Button
            size={'small'}
            onClick={() => {
              if (remarks) {
                dispatch(setSpinnerLoading('Voiding'));
                CallAPI(
                  '/api/billing/voidBill',
                  {
                    table_prefix: prefix,
                    invno: currentInvoiceNumber,
                    bill_status: 'Void',
                    remarks: remarks,
                  },
                  token,
                  (e) => {
                    if (!e.data.error) {
                      dispatch(
                        setAlertWithTitle({
                          title: 'Success',
                          msg: `Bill successfully  ${'Deleted'}`,
                        })
                      );
                      dispatch(setviewBillModalStatus(false));
                    } else {
                      dispatch(
                        setAlertWithTitle({
                          title: 'Alert',
                          msg: 'Something went wrong',
                        })
                      );
                    }
                  },
                  dispatch,
                  () => {
                    dispatch(setSpinnerLoading(false));
                  }
                );
              } else {
                dispatch(
                  setAlertWithTitle({
                    title: 'Alert',
                    msg: 'Remarks required',
                  })
                );
              }
            }}
            style={{
              backgroundColor: '#e97a7a',
              color: 'white',

              textTransform: 'none',
              width: 'auto',
              justifyContent: 'start',
              marginRight: '4px',
            }}
            variant="contained"
          >
            <div className="px-6">{'Void'}</div>{' '}
          </Button>
        }
      />
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
    </div>
  );
};

export default ViewBill;
